<template>
  <!-- 右侧广告栏 -->
  <div>
    <div class="aside-course">
      <router-link to="/course/2"
        ><img src="@/assets/leetcode/leetcode1.png"
      /></router-link>
    </div>
<!--    <div class="aside-course">-->
<!--      <router-link to="/leetcodeRewards"-->
<!--        ><img src="@/assets/leetcode/leetcode2.png"-->
<!--      /></router-link>-->
<!--    </div>-->
<!--    <div class="aside-course">-->
<!--      <router-link to="/liveRoom"-->
<!--        ><img src="@/assets/live/liveAside.png"-->
<!--      /></router-link>-->
<!--    </div>-->

<!--    <div class="aside-zx">-->
<!--      <div class="aside-zx-tt">北美面经直播微信群</div>-->
<!--      <div class="zx-ewm">-->
<!--        <img :src="qrcode && qrcode.imageUrl" />-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>
<script>
import { getCourseBanner } from "@/service";
export default {
  name: "SideAdvertisement",
  data() {
    return {
      qrcode: {},
    };
  },
  mounted() {
    this.getQrcodes();
  },
  methods: {
    getQrcodes() {
      getCourseBanner(7)
        .then((res) => {
          console.log(res);
          if (!res.result.code) {
            this.qrcode = res.result[0];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.aside-course {
  font-size: 0;
  margin-bottom: 10px;
  img {
    width: 100%;
    vertical-align: top;
  }
}
.aside-course:hover {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.aside-zx {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 22px 0;
  text-align: center;
}

.aside-zx-tt {
  font-size: 14px;
  color: #34495e;
  margin-bottom: 20px;
  text-align: center;
}
.zx-ewm {
  border-radius: 2px;
  border: 3px solid #0075f6;
  width: 106px;
  height: 106px;
  margin: 0 auto;
  font-size: 0;
  img {
    width: 100%;
    vertical-align: top;
  }
}
</style>