// 打卡
import request from '../utils/request';

// 小组刷题榜
export async function groupTestPrep(type, size) {
    try {
        return await request({
            url: `/clockInRank/groupRange/${type}?size=${size ? size :10}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 个人刷题榜
export async function personalTestPrep(type, size) {
    try {
        return await request({
            url: `/clockInRank/personalRange/${type}?size=${size ? size :10}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 热门小组
export async function hotGroup() {
    try {
        return await request({
            url: `/clockInGroup/hot?size=5`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}


// 正在招募小组
export async function groupRecruitment(currentPage, searchKey) {
    try {
        return await request({
            url: `/clockInGroup/recruitment?size=5${currentPage ? '&current=' + currentPage :'&current=1'}${searchKey ? '&groupName=' + searchKey : ''}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 加入小组
export async function joinGroup(command, id) {
    try {
        return await request({
            url: `/clockInGroup/join`,
            method: 'post',
            data: {
                command: command,
                id: id
            }
        });
    } catch (error) {
        return {};
    }
}

// 获取个人打卡信息 

export async function getClockStatistics() {
    try {
        return await request({
            url: `/clockInUserClock/statistics`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 获取个人小组

export async function getUserGroup() {
    try {
        return await request({
            url: `/clockInGroup/myGroup`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 创建小组 

export async function createGroup(data) {
    try {
        return await request({
            url: `/clockInGroup/create`,
            method: 'post',
            data: data
        });
    } catch (error) {
        return {};
    }
}
// 编辑小组
export async function editGroup(data) {
    try {
        return await request({
            url: `/clockInGroup/editGroup`,
            method: 'put',
            data: data
        });
    } catch (error) {
        return {};
    }
}


// 获取小组圈子信息动态

export async function getGroupCommunication(type, currentPage) {
    try {
        return await request({
            url: type == 'hot' ? `/posts/notice/hotNotice${currentPage?'?current=' + currentPage : ''}` : `/posts/notice/newNotice${currentPage?'?current=' + currentPage : ''}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 获取打卡主页主要提示
export async function getMainNotice() {
    try {
        return await request({
            url: '/clockInNotice',
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 首页动态发布

export async function publishActivity(data) {
    try {
        return await request({
            url: '/posts/notice/issueDynamic',
            method: 'post',
            data: data
        });
    } catch (error) {
        return {};
    }
}


// 获取个人打卡目标

export async function getTarget() {
    try {
        return await request({
            url: '/clockInUserTarget',
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 初始设定个人打卡目标

export async function setPrivateInitTarget(data) {
    try {
        return await request({
            url: '/clockInUserTarget',
            method: 'post',
            data: data
        });
    } catch (error) {
        return {};
    }
}

// 更改个人打卡目标
export async function setPrivateTarget(data) {
    try {
        return await request({
            url: '/clockInUserTarget',
            method: 'put',
            data: data
        });
    } catch (error) {
        return {};
    }
}

// 获取完成目标细节 groupId不传为个人

export async function getTargetDetail(groupId) {
    try {
        return await request({
            url: '/clockInGroup/target',
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}


// 获取打卡天数
export async function getClockDays() {
    try {
        return await request({
            url: '/clockInUserClock/clockDays',
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}


// 获取打卡日期
export async function getClockDates(date) {
    try {
        return await request({
            url: '/clockInUserClock/everyDayClockNum?month=' + date,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 根据打卡日期获取打卡记录题目
export async function getClockRecordsByDate(day, page, keyword) {
    try {
        return await request({
            url: `/clockInUserClock/myClockRecordByDate?day=${day}&current=${page}&keyWord=${keyword?keyword:''}&size=15`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

//更改历史打卡记录内的难度
export async function changeDifficulty(id, difficulty) {
    try {
        return await request({
            url: `/clockInUserClock/setDifficulty`,
            method: 'put',
            data: {
                id,
                difficulty
            }
        });
    } catch (error) {
        return {};
    }
}

// 根据题号查找题目 

export async function searchQuestions(questionNums) {
    try {
        return await request({
            url: `/clockInUserClock/getQuestionByQuestionNum?questionNums=${questionNums}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}

// 获取我的总打卡记录
export async function getMyClockRecords(currentPage, questionNum) {
    try {
        return await request({
            url: `/clockInUserClock/myClockRecord?current=${currentPage}&questionNum=${questionNum}`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}


// 批量打卡
export async function saveClocks(data) {
    try {
        return await request({
            url: `/clockInUserClock/clockBatch`,
            method: 'post',
            data: data
        });
    } catch (error) {
        return {};
    }
}

// 写笔记
export async function saveNotes(data) {
    try {
        return await request({
            url: `/clockInUserClock/setNode`,
            method: 'put',
            data: data
        });
    } catch (error) {
        return {};
    }
}



// 我的小组部分

// 获取小组详情
export async function getGroupDetail(groupId) {
    try {
        return await request({
            url: `/clockInGroup/groupDetail/${groupId}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 获取官方课程选择框选项
export async function getOfficialClasses() {
    try {
        return await request({
            url: `/clockInGroup/select`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 获取官方课程选择框tab
export async function getOfficialClassTabs(templateId) {
    try {
        return await request({
            url: `/clockInGroup/classify?templateId=${templateId}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// tab获取数据
export async function getOfficialClassDetail(tabId, current) {
    try {
        return await request({
            url: `/clockInUserClock/myClockRecordByClassify?typeId=${tabId}&current=${current}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 取消打卡
export async function cancelClock(id) {
    try {
        return await request({
            url: `/clockInUserClock/cancelClock/${id}`,
            method: 'delete'
        });
    } catch (error) {
        return {};
    }
}


// 发布主题内容
export async function publishNotice(data) {
    try {
        return await request({
            url: `/clockInGroupNotice`,
            method: 'post',
            data: data
        });
    } catch (error) {
        return {};
    }
}

// 获取主题内容
export async function getMainThemes(groupId, current) {
    try {
        return await request({
            url: `/clockInGroupNotice?groupId=${groupId}&current=${current ? current :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 检测是否在小组

export async function existInGroup(groupId) {
    try {
        return await request({
            url: `/clockInGroup/existGroup/${groupId}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 退出小组
export async function quitGroup(groupId) {
    try {
        return await request({
            url: `/clockInGroup/quit/${groupId}`,
            method: 'delete'
        });
    } catch (error) {
        return {};
    }
}


// 获取小组个人榜 


export async function getRankingList(type, groupId) {
    try {
        return await request({
            url: `/clockInRank/groupUserRange/${type}/${groupId}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}


// 获取小组内全部动态

export async function getInGroupCommutes(groupId, current) {
    try {
        return await request({
            url: `/posts/notice/groupNotice?groupId=${groupId}&current=${current ? current :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 获取小组内个人全部动态

export async function getInGroupPrivateCommutes(current) {
    try {
        return await request({
            url: `/posts/notice/myNotice?current=${current ? current :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 小组内发布动态
export async function publishInGroupActivity(data) {
    try {
        return await request({
            url: `/posts/notice/issueDynamic`,
            method: 'post',
            data: data
        });
    } catch (error) {
        return {};
    }
}

// 获取组内排名
export async function getInGroupRanking(groupId) {
    try {
        return await request({
            url: `/clockInGroup/rank/${groupId}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}


// 获取动态详情
export async function getActivityDetail(id) {
    try {
        return await request({
            url: `/clockInPersonalNotice/detail/${id}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}

// 发表评论或者点赞
export async function publishCommentsOrLikes(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}

// 获取动态的评论列表

export async function getActivityComments(personalNoticeId, current) {
    try {
        return await request({
            url: `/publicComment/comment/${personalNoticeId}?current=${current ? current :1}&type=0`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}





// 获取观看详情
export async function getWatchDetail(algorithmId) {
    try {
        return await request({
            url: `/section/getAlgorithmDetail/${algorithmId}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}


// 获取动态子级评论
export async function getActivityChildComments(commentId, page) {
    try {
        return await request({
            url: `/publicComment/commentChildren/${commentId}?current=${page? page :1}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }
}
// 浏览动态
export async function viewActivity(id) {
    try {
        return await request({
            url: `/browse/` + id,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}